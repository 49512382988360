import { render, staticRenderFns } from "./TrainingListAdmin.vue?vue&type=template&id=0811ed19&scoped=true&"
import script from "./TrainingListAdmin.vue?vue&type=script&lang=js&"
export * from "./TrainingListAdmin.vue?vue&type=script&lang=js&"
import style0 from "./TrainingListAdmin.vue?vue&type=style&index=0&id=0811ed19&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0811ed19",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
